import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";

import Typed from "typed.js";

import BackgroudImage from "../assets/images/bg/cascavel_aereo.jpg";
import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
import Review from "../component/Testimonial";
import Switcher from "../component/Switcher";

/**
 * Index Component
 */
export default function Index() {

  const [isOpen, setMenu] = useState(true);
  window.addEventListener("scroll", windowScroll);

  const toggleMenu = () => {
    setMenu(!isOpen)
  }

  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>Expansão</b>",
        "<b>Estoque</b>",
        "<b>Fluxo de Caixa</b>",
        "<b>Marketing</b>",
        "<b>Consolidação de Dívidas</b>",
        "<b>Emergências e Contingências</b>",
        "<b>Oportunidades de Investimento</b>",
      ],
      backDelay: 2000,
      loop: true,
      startDelay: 300,
      typeSpeed: 50,
      backSpeed: 25,
    });
  }, 500);


  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  return (
    <>
      <div>
        <nav className="navbar" id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            <a className="navbar-brand" href="index.html">
              <span className="inline-block dark:hidden">
                <img src={LogoDark} className="l-dark" alt="" />
                <img src={LogoLight} className="l-light" alt="" />
              </span>
              <img
                src={LogoLight}
                className="hidden dark:inline-block"
                alt=""
              />
            </a>

            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <ul className="list-none menu-social mb-0">
                {/*<li className="inline ms-1">*/}
                {/*  <Link2 to="#">*/}
                {/*    <span className="login-btn-primary">*/}
                {/*      <span className="btn btn-sm btn-icon rounded-full bg-blue-600 hover:bg-orange-700 border-blue-600 hover:border-orange-700 text-white">*/}
                {/*        <i className="uil uil-github"></i>*/}
                {/*      </span>*/}
                {/*    </span>*/}
                {/*    <span className="login-btn-light">*/}
                {/*      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">*/}
                {/*        <i className="uil uil-github"></i>*/}
                {/*      </span>*/}
                {/*    </span>*/}
                {/*  </Link2>*/}
                {/*</li>*/}
                <li className="inline ms-1">
                  <Link2 to="https://www.facebook.com/cascavel.mastercredito.9" target="_blank">
                    <span className="login-btn-primary">
                      <span className="btn btn-sm btn-icon rounded-full bg-blue-600 hover:bg-orange-700 border-blue-600 hover:border-orange-700 text-white">
                        <i className="uil uil-facebook"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <i className="uil uil-facebook"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
                <li className="inline ms-1">
                  <Link2 to="https://www.instagram.com/cascavel_master_credito/" target="_blank">
                    <span className="login-btn-primary">
                      <span className="btn btn-sm btn-icon rounded-full bg-blue-600 hover:bg-orange-700 border-blue-600 hover:border-orange-700 text-white">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
              </ul>
              <button
                type="button"
                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <span className="sr-only">Navigation Menu</span>
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div
              className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
              id="menu-collapse"
            >
              <ul className="navbar-nav nav-light" id="navbar-navlist">
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="home"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Início</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*  to="about"*/}
                {/*>*/}
                {/*  <span className="nav-link">Sobre nós</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="features"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Serviços</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="portfolio"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Portfolio</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="testi"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Review</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="pricing"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Pricing</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="blog"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Blog</span>*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*  className="nav-item"*/}
                {/*  to="contact"*/}
                {/*  activeclassname="active"*/}
                {/*  spy={true}*/}
                {/*  smooth={true}*/}
                {/*  duration={500}*/}
                {/*>*/}
                {/*  <span className="nav-link">Contato</span>*/}
                {/*</Link>*/}
              </ul>
            </div>
          </div>
        </nav>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Dinheiro na hora<br />
                para{" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"
                  data-type='[ "Business", "Startups", "Digital Agency", "Marketing" ]'
                ></span>
              </h4>

              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
                Para qualquer motivo, precisando de dinheiro, nós temos a solução!
              </p>

              <div className="relative mt-10">
                <Link2
                  to="https://api.whatsapp.com/send?phone=45998081649&text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20empréstimos."
                  className="btn bg-blue-600 hover:bg-orange-700 border-blue-600 hover:border-orange-700 text-white rounded-md"
                  target="_blank"
                >
                  Entre em contato
                </Link2>
              </div>
            </div>
          </div>
        </section>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Portfolio section */}
        {/*<Portfolio />*/}

        {/* Review section */}
        {/*<Review />*/}

        {/* Pricing section */}
        {/*<Pricing />*/}

        {/* Blog section */}
        {/*<Blog />*/}

        {/* Contact section */}
        {/*<Contact />*/}

        {/* Footer section */}
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>

    </>
  );

}

