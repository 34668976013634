import React from "react";
import { Link } from "react-router-dom";

// import StrategyImage from "../assets/images/svg/design-thinking.svg";
// import departmentImage from "../assets/images/svg/coding.svg";
// import launchImage from "../assets/images/svg/office-desk.svg";

export default function Services() {
  const team = [


    {
      title: 'Expansão de Negócios',
      icon: 'cash',
      subtext: 'Investir em infraestrutura física ou tecnológica para aumentar a capacidade de produção ou expandir operações.',
    },
    {
      title: 'Atualização de Equipamentos',
      icon: 'cash',
      subtext: 'Financiar a compra de maquinário ou tecnologia mais recente para melhorar a eficiência operacional.',
    },
    {
      title: 'Estoque',
      icon: 'cash',
      subtext: 'Aumentar o estoque para atender a uma demanda crescente ou se preparar para a alta temporada de vendas.',
    },
    {
      title: 'Fluxo de Caixa',
      icon: 'cash-sync',
      subtext: 'Garantir capital de giro para cobrir despesas operacionais diárias durante períodos de fluxo de caixa irregular.',
    },
    {
      title: 'Investimento em Marketing',
      icon: 'cash',
      subtext: 'Implementar ou intensificar estratégias de marketing para aumentar a base de clientes e a receita.',
    },
    {
      title: 'Desenvolvimento de Produto',
      icon: 'cash',
      subtext: 'Financiar pesquisa e desenvolvimento para inovar ou melhorar a linha de produtos existente.',
    },
    {
      title: 'Consolidação de Dívidas',
      icon: 'cash',
      subtext: 'Consolidar dívidas existentes para simplificar as finanças e potencialmente reduzir as taxas de juros.',
    },
    {
      title: 'Emergências e Contingências',
      icon: 'cash',
      subtext: 'Assegurar fundos para situações imprevistas ou emergências que possam afetar as operações.',
    },
    {
      title: 'Oportunidades de Investimento',
      icon: 'cash',
      subtext: 'Aproveitar oportunidades de investimento estratégico que possam surgir e exigir ação rápida.',
    },
    {
      title: 'Renovações',
      icon: 'cash',
      subtext: 'Melhorar as instalações existentes para cumprir com regulamentos atualizados ou para aumentar o valor do imóvel.',
    },
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">

            <div>
              <h6 className="text-blue-600 text-base font-medium uppercase mb-2">O que fazemos?</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Nossos Serviços</h3>
            </div>

            <div>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">EMPRÉSTIMOS</h3>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">Oferecemos empréstimo direto com garantia de
                bem móvel ou imóvel (a garantia varia de acordo com o valor do
                empréstimo). Emprestamos dinheiro direto no seu cartão de crédito, sem a necessidade de análises do seu
                CNPJ, e se você está sem limite no cartão, nós dividimos o valor em vários cartões.</p>

              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0 mt-2">FINANCIAMENTO</h3>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">Se você está querendo realizar um projeto,
                reformar ou melhorar o seu negócio nós também podemos te ajudar,
                financiamos o seu projeto, máquinas e outros materiais para sua empresa.</p>
            </div>

          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key}
                   className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-blue-600/5 text-blue-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-blue-600 dark:text-white dark:hover:text-blue-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>

                  {/*<div className="mt-5">*/}
                  {/*  <Link to="#" className="btn btn-link hover:text-blue-600 dark:hover:text-blue-600 after:bg-blue-600 dark:text-white transition duration-500">Read More <i className="uil uil-arrow-right"></i></Link>*/}
                  {/*</div>*/}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/*<div className="container md:mt-24 mt-16">*/}
        {/*  <div className="grid grid-cols-1 pb-8 text-center">*/}
        {/*    <h6 className="text-blue-600 text-base font-medium uppercase mb-2">Work Process</h6>*/}
        {/*    <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Digital System For Our Business</h3>*/}

        {/*    <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>*/}
        {/*  </div>*/}

        {/*  <div className="grid grid-cols-1 mt-8">*/}
        {/*    <div className="timeline relative">*/}
        {/*      <div className="timeline-item">*/}
        {/*        <div className="grid sm:grid-cols-2">*/}
        {/*          <div className="">*/}
        {/*            <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">*/}
        {/*              /!*<img src={StrategyImage} className="h-64 w-64" alt="" />*!/*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className="mt-4 md:mt-0">*/}
        {/*            <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">*/}
        {/*              <h5 className="text-lg dark:text-white mb-1 font-medium">Strategy</h5>*/}
        {/*              <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*      <div className="timeline-item mt-5 pt-4">*/}
        {/*        <div className="grid sm:grid-cols-2">*/}
        {/*          <div className="md:order-1 order-2">*/}
        {/*            <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">*/}
        {/*              <h5 className="text-lg dark:text-white mb-1 font-medium">Development</h5>*/}
        {/*              <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className="md:order-2 order-1">*/}
        {/*            <div className="duration duration-right md:ms-7 relative">*/}
        {/*              /!*<img src={departmentImage} className="h-64 w-64" alt="" />*!/*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*      <div className="timeline-item mt-5 pt-4">*/}
        {/*        <div className="grid sm:grid-cols-2">*/}
        {/*          <div className="mt-4 mt-sm-0">*/}
        {/*            <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">*/}
        {/*              /!*<img src={launchImage} className="h-64 w-64" alt="" />*!/*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className="mt-4 mt-sm-0">*/}
        {/*            <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">*/}
        {/*              <h5 className="text-lg dark:text-white mb-1 font-medium">Launch</h5>*/}
        {/*              <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </section>
    </>
  );

}
